<template>
  <div
    id="post-modal"
    class="absolute z-20"
    @keyup.left="$emit('requestedPrevious')"
    @keyup.right="$emit('requestedNext')"
  >
    <BaseModal id="post-base-modal" v-model="showModalInt" :showHeaders="false">
      <PostWrapper
        id="post-base-wrapper"
        v-if="showModalInt && post"
        :loading="loading"
        :post="post"
        :showListControls="true"
        @close="closeOrReturn"
        @requestedPrevious="$emit('requestedPrevious')"
        @requestedNext="$emit('requestedNext')"
      >
      </PostWrapper>
    </BaseModal>
  </div>
</template>
<script>
import PostWrapper from "@/components/posts/PostWrapper.vue";

export default {
  components: {
    PostWrapper
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    post: {
      type: [Object, null]
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      showModalInt: this.value
    };
  },
  watch: {
    value(val) {
      this.showModalInt = val;
      this.$nextTick(() => {
        if (val) {
          const modal = document.getElementById("post-base-modal");
          modal.focus();
        }
      });
    },
    showModalInt(val) {
      this.$emit("input", val);
    }
  },
  methods: {
    closeOrReturn() {
      this.showModalInt = false;
    }
  }
};
</script>
<style lang="postcss">
#post-base-modal {
  .modal-content {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 0;
  }
}

@media screen and (min-width: 600px) {
  #post-base-modal {
    .modal-content {
      width: fit-content;
      border-radius: 0;
      max-height: var(--desktop-post-height);
    }
  }
}
</style>
