<template>
  <InfiniteScroll
    :loading="loading"
    @loadMore="loadMore"
    :hasMoreToLoad="hasMorePosts"
  >
    <PostModal
      :post="activePost"
      v-model="showPostModal"
      @requestedPrevious="requestedPrevious"
      @requestedNext="requestedNext"
    />
    <div
      class="container mx-auto relative w-full flex flex-row mt-14 h-11 mb-6 max-w-desktop"
    >
      <BackButton
        type="dark"
        @click="
          $router.push({
            name: 'ProfilePage',
            params: { profileId: $route.params.profileId }
          })
        "
        class="absolute left-4 top-0"
      />
      <div class="flex flex-grow justify-center">
        <h3>
          Posts
        </h3>
      </div>
    </div>
    <p v-if="displayName" class="font-tiny w-full text-center mb-2 capitalize">
      Posts from {{ displayName }}
    </p>
    <div
      v-if="postsLocal && postsLocal.length > 0"
      id="post-list"
      class="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 mx-auto w-full max-w-desktop px-6 md:px-0 mb-4"
    >
      <div
        v-for="(post, index) in postsLocal"
        :key="post.id"
        class="text-center mx-auto my-0 relative rounded-sm overflow-hidden h-post-height-small w-post-width-small md:w-post-width-large md:h-post-height-large"
        @click="displayPost(post, index)"
      >
        <PostRender class="relative" :post="post" typeOfRender="list" />
        <template v-if="post.publishState !== 'published'">
          <div
            class="disabled-post-bg h-full w-full absolute top-0 left-0"
          ></div>
          <DisabledPostStatus :post="post" />
        </template>
      </div>
    </div>
  </InfiniteScroll>
</template>

<script>
import PostModal from "@/components/posts/PostModal.vue";
import PostRender from "@/components/posts/PostRender.vue";
import BackButton from "@/components/shared/BackButton.vue";
import {
  GET_POSTS_FROM_PROFILE,
  GET_POSTS_FROM_ID
} from "@/graphql/queries/post/postsQueries.js";
import InfiniteScroll from "@/components/shared/InfiniteScroll.vue";
import DisabledPostStatus from "@/components/posts/DisabledPostStatus.vue";

export default {
  name: "ProfilePostsPage",
  components: {
    BackButton,
    PostRender,
    PostModal,
    InfiniteScroll,
    DisabledPostStatus
  },
  data() {
    return {
      loading: true,
      activePost: null,
      activePostIndex: null,
      showPostModal: false,
      postsLocal: [],
      nextToken: null,
      displayName: "",
      profileId: ""
    };
  },
  beforeMount() {
    this.nextToken = null;
    this.postsLocal = [];
  },
  apollo: {
    profile: {
      query: GET_POSTS_FROM_PROFILE,
      update(data) {
        this.postsLocal = [];
        this.displayName = data.getProfile.displayName;
        this.postsLocal.push(...data.getProfile.posts.posts);
        this.profileId = data.getProfile.id;
        this.$nextTick(() => {
          this.loading = false;
        });
        return data.getProfile;
      },
      variables() {
        return {
          screenName: this.$route.params.screenName
        };
      },
      // can be improved by pushing only new items on the response and fetchPolicy cache-and-network
      fetchPolicy: "network-only"
    },
    posts: {
      query: GET_POSTS_FROM_ID,
      update(data) {
        this.postsLocal.push(...data.getPosts.posts);
        this.$nextTick(() => {
          this.loading = false;
        });
        return data.getPosts;
      },
      variables() {
        return {
          id: this.profileId,
          nextToken: this.nextToken
        };
      },
      skip() {
        return this.profileId == "" || this.nextToken == null;
      },
      // can be improved by pushing only new items on the response and fetchPolicy cache-and-network
      fetchPolicy: "network-only"
    }
  },
  computed: {
    hasMorePosts() {
      if (this.posts) {
        return this.posts.nextToken ? true : false;
      } else {
        return this.profile?.posts.nextToken ? true : false;
      }
    }
  },
  methods: {
    displayPost(post, index) {
      this.activePostIndex = index;
      this.activePost = post;
      this.showPostModal = true;
    },
    loadMore() {
      this.loading = true;
      if (this.posts) {
        if (this.posts.nextToken) {
          this.nextToken = this.posts.nextToken;
        }
      } else {
        this.nextToken = this.profile?.posts.nextToken ?? null;
      }
    },
    requestedPrevious() {
      if (this.activePostIndex === 0) {
        this.showPostModal = false;
        this.activePostIndex = null;
      } else {
        this.activePostIndex--;
        this.activePost = this.postsLocal[this.activePostIndex];
      }
    },
    requestedNext() {
      if (
        this.activePostIndex >= this.postsLocal.length - 3 &&
        this.hasMorePosts &&
        !this.loading
      ) {
        this.loadMore();
      }
      if (
        this.activePostIndex === this.postsLocal.length - 1 &&
        !this.posts.nextToken
      ) {
        this.showPostModal = false;
        this.activePostIndex = null;
      } else {
        if (this.postsLocal[this.activePostIndex + 1]) {
          this.activePostIndex++;
          this.activePost = this.postsLocal[this.activePostIndex];
        }
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.disabled-post-bg {
  background-color: #0009;
}

.disabled-post {
  top: 50%;

  @apply text-white absolute text-center justify-center flex w-full z-10 items-center;
}
</style>
